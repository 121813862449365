import { template as template_31f661f7b3a64ad8a1e91e3ba43d74c4 } from "@ember/template-compiler";
const FKControlMenuContainer = template_31f661f7b3a64ad8a1e91e3ba43d74c4(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
