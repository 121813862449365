import { template as template_6e3b7c9d0ed74fc49b41e4c7002c9d43 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_6e3b7c9d0ed74fc49b41e4c7002c9d43(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
