import { template as template_0db4b6f641b24cd48c904cb2592d8e20 } from "@ember/template-compiler";
import NotificationsTracking from "discourse/components/notifications-tracking";
const GroupNotificationsTracking = template_0db4b6f641b24cd48c904cb2592d8e20(`
  <NotificationsTracking
    @onChange={{@onChange}}
    @levelId={{@levelId}}
    @showCaret={{false}}
    @showFullTitle={{false}}
    @prefix="groups.notifications"
    class="group-notifications-tracking"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default GroupNotificationsTracking;
