import { template as template_ea5f7d24fb854172b82aa8388141c797 } from "@ember/template-compiler";
const SidebarSectionMessage = template_ea5f7d24fb854172b82aa8388141c797(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
