import { template as template_e9e85afcf42340f3ba4aae6e87bb1fc7 } from "@ember/template-compiler";
const WelcomeHeader = template_e9e85afcf42340f3ba4aae6e87bb1fc7(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
